@use '@angular/material' as mat;
@use 'sass:map';
@import 'palettes.style.scss';

$prosumer-primary: mat.m2-define-palette(
  $prosumer-primary-palette,
  100,
  500,
  700
);
$prosumer-accent: mat.m2-define-palette(
  $prosumer-accent-palette,
  100,
  500,
  700
);
$prosumer-warn: mat.m2-define-palette($prosumer-warn-palette, 100, 500, 700);

$prosumer-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $prosumer-primary,
      accent: $prosumer-accent,
      warn: $prosumer-warn,
    ),
  )
);

/* Theming map for extracting colors */
$primary: map.get($prosumer-theme, primary);
$accent: map.get($prosumer-theme, accent);
$warn: map.get($prosumer-theme, warn);
$foreground: map.get($prosumer-theme, foreground);
$background: map.get($prosumer-theme, background);

/* Colors to be used in your styles */
// Primary-based colors
$dark-blue: mat.m2-get-color-from-palette($primary, darker);
$blue: mat.m2-get-color-from-palette($primary);
$light-blue: mat.m2-get-color-from-palette($primary, lighter);

// Accent-based colors
$green: mat.m2-get-color-from-palette($accent, darker);
$orange: mat.m2-get-color-from-palette($accent);
$yellow: mat.m2-get-color-from-palette($accent, lighter);

// Warn-based colors
$dark-red: mat.m2-get-color-from-palette($warn, darker);
$red: mat.m2-get-color-from-palette($warn);
$light-red: mat.m2-get-color-from-palette($warn, lighter);

// Background-based colors
$background-color: mat.m2-get-color-from-palette($background, background);
$hover-color: mat.m2-get-color-from-palette($background, hover);
$hint-color: mat.m2-get-color-from-palette($foreground, hint-text);
$label-color: mat.m2-get-color-from-palette($foreground, label);
$disabled-text: mat.m2-get-color-from-palette($foreground, disabled-text);
$disabled-color: mat.m2-get-color-from-palette($background, unselected-chip);

// Foreground-based colors
$text-color: mat.m2-get-color-from-palette($foreground, text);
$light-gray: mat.m2-get-color-from-palette($foreground, contrast);
$gray: mat.m2-get-color-from-palette($foreground, secondary-text);
$foreground-divider-color: mat.m2-get-color-from-palette($foreground, divider);

/* Others */
// TODO The following should be changed and reference the extracted theme palette
$white: #fff;
$black: #000;
$dirty-white: #f5f5f5;
$tooltip-anchor-color: rgba(0, 0, 0, 0.12) !important;
$smoke-gray: #5b646b;
$blue-bolt: rgb(0, 186, 249);
$gray-600: rgb(113, 128, 150);
$hover: rgba(0, 0, 0, 0.04);

// Custom Colors
$custom-deep-purple: #9575cd;
$custom-light-blue: #4fc3f7;
$custom-green: #81c784;
$custom-red: #e57373;
$custom-orange: #ffb74d;

// Opacity
$opacity-default: 0.8;

// Transitions
$transition-seconds: 0.2s;
$transition-effect: cubic-bezier(0.35, 0, 0.25, 1);
$transition-opacity: opacity $transition-seconds $transition-effect;
$transition-bg: background-color $transition-seconds $transition-effect;
$transition-all: all $transition-seconds $transition-effect;
