@use '@angular/material' as mat;
@mixin prosumer-tooltip-theme($theme) {
  prosumer-tooltip-message .tooltip {
    background-color: $light-gray;
    // @include mat-mat.elevation(4);
    @include mat.elevation(4);
  }

  prosumer-tooltip-anchor mat-icon {
    cursor: help;
    margin-top: 7px !important;
    color: $tooltip-anchor-color;
  }

  prosumer-tooltip-anchor.attached-component mat-icon {
    margin-top: 3.5px !important;
    font-size: 18px !important;
  }

  .mat-mdc-tooltip.error-message-tooltip {
    top: 4px;

    .mdc-tooltip__surface {
      background: var(--mat-form-field-error-text-color);
      font-weight: 600;
      color: white;
    }
  }
}
