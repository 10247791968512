@mixin prosumer-scenario-compare-page-theme($theme) {
  prosumer-compare-summary {
    .mat-mdc-chip-selected {
      background-color: $custom-light-blue;
      & .mat-mdc-chip-action {
        padding-left: 12px !important;
        & .mat-mdc-chip-action-label {
          color: white !important;
          display: flex !important;
        }
      }
      & .mat-mdc-chip-graphic {
        display: none !important;
      }
    }
    .mat-mdc-chip-selected.deep-purple-bg {
      background-color: $custom-deep-purple !important;
    }
    .mat-mdc-chip-selected.light-blue-bg {
      background-color: $custom-light-blue !important;
    }
    .mat-mdc-chip-selected.green-bg {
      background-color: $custom-green !important;
    }
    .mat-mdc-chip-selected.red-bg {
      background-color: $custom-red !important;
    }
    .mat-mdc-chip-selected.orange-bg {
      background-color: $custom-orange !important;
    }
  }

  prosumer-scenario-compare-page {
    .ngx-slider {
      @extend .custom-slider-type2;
    }
    .mat-mdc-tab-label-container {
      place-content: center;
      .mat-mdc-tab-list {
        flex-grow: initial;
      }
    }
    .mat-button-toggle-group {
      @extend .custom-button-toggle-group;
    }

    .chart-legend {
      .legend-labels {
        margin-left: 10px !important;
        background: none;
      }

      .legend-label {
        color: $gray;
      }

      .legend-title {
        font-weight: normal;
      }
    }
  }

  prosumer-compare-main {
    .compare-main-container {
      background-color: white;
    }

    .compare-main {
      background-color: $white;
    }

    .compare-main-header {
      color: $black;
    }

    .kpi-name {
      color: $black !important;
    }

    .kpi-value {
      color: $black !important;
    }

    .header {
      background-color: $light-gray;
    }

    .kpi-percent-positive {
      color: $green;
    }

    .kpi-percent-negative {
      color: $dark-red;
    }
  }
}
