@mixin custom-mat-checkbox-theme($theme) {
  .mdc-checkbox {
    color: white;
  }

  .mat-mdc-checkbox-checked {
    & .mdc-checkbox__background {
      border-color: #007acd !important;
      background-color: #007acd !important;
    }
  }
}
