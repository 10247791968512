@mixin custom-mat-sidenav-theme($theme) {
  .mat-sidenav-content {
    .page-content {
      transition: margin $transition-seconds $transition-effect;
    }
  }

  /* Start - PR-820 Space Optimization */
  .mat-sidenav {
    .mat-mdc-list-item {
      height: 44px !important;
    }
  }
  /* End - PR-820 Space Optimization */
}
