@mixin custom-mat-button-toggle-group-theme($theme) {
  .custom-button-toggle-group {
    $selected-color: $blue;
    border: none !important;

    .mat-button-toggle {
      font-weight: normal !important;
      background: none !important;
      border: 2px solid transparent !important;
      border-radius: 5px !important;
      margin-bottom: 10px !important;
      margin-right: 5px !important;
      transition: $transition-all;
    }

    .mat-button-toggle-label-content {
      line-height: 36px !important;
    }

    .mat-button-toggle-checked {
      color: $selected-color !important;
      background: none !important;
      background-image: linear-gradient(
        -45deg,
        rgba($selected-color, 0.08),
        rgba($selected-color, 0.03),
        rgba($selected-color, 0.08)
      ) !important;
      border-color: $selected-color !important;
      transition: $transition-all;
    }

    .mat-button-toggle-focus-overlay {
      border-bottom: 0 !important;
    }
  }

  .mat-button-toggle-group {
    & .mat-button-toggle {
      align-content: center;
    }
  }
}
