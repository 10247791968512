@mixin custom-mat-tab-theme($theme) {
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs {
    & > .mat-mdc-tab-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .mdc-tab.mat-mdc-tab {
        flex-grow: 0;
      }
    }
  }
}
