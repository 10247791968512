@mixin prosumer-compare-dialog-theme($theme) {
  prosumer-scenario-compare-dialog {
    .mat-mdc-list-option,
    .mdc-list-item__content,
    .mat-list-item-ripple {
      border-radius: 5px;
    }

    .mdc-list-item__end {
      display: none;
    }

    .mat-mdc-list-option {
      border: 2px solid transparent;
      font-size: 13px !important;
      font-weight: 400;
      height: 36px !important;
      margin-bottom: 5px;
      &:focus {
        outline: none;
      }
    }

    .mat-mdc-list-option.selected {
      $selected-color: $orange;
      background: transparent !important;
      background-image: linear-gradient(
        -45deg,
        rgba($selected-color, 0.08),
        rgba($selected-color, 0.03),
        rgba($selected-color, 0.08)
      ) !important;
      border-color: $selected-color;
      margin-bottom: 5px;
    }
  }
}
