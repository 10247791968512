@mixin custom-mat-expansion-panel-theme($theme) {
  .mat-expansion-panel-header {
    transition: $transition-bg;
    margin-bottom: 20px;
    &:hover {
      transition: $transition-bg;
      background-color: $dirty-white !important;
    }
  }

  .mat-mdc-dialog-container {
    .mat-expansion-panel {
      box-shadow: none !important;
      .mat-expansion-panel {
        margin-right: -12px;
      }
    }

    .mat-expansion-panel-header-title {
      font-size: larger !important;

      h2,
      h3 {
        margin-bottom: 5px;
      }
    }

    /* Start - PR-820 Space Optimization */
    .mat-expansion-panel-header {
      margin-bottom: 5px;
      padding: 0 12px;
    }

    .mat-expansion-panel-body {
      padding-bottom: 5px;
      padding: 0 12px 12px;

      .mat-expansion-panel-body,
      .mat-expansion-panel-header {
        padding-left: 0;
      }
    }
    /* End - PR-820 Space Optimization */

    .align-expansion-panel {
      .mat-expansion-panel-body,
      .mat-expansion-panel-header {
        padding-left: 0;
      }
    }

    prosumer-storage,
    prosumer-generator,
    prosumer-converter,
    prosumer-station {
      .sizing-parameters .mat-expansion-panel-body {
        padding-bottom: 34px;
      }
    }
  }
}
