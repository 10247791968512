@mixin prosumer-dashboard-page-theme($theme) {
  prosumer-dashboard-page {
    .infinity {
      font-size: 110px;
      line-height: 80px;
      font-weight: 500;
    }
  }

  prosumer-dashboard-page eyes-progress-card .mat-mdc-card {
    height: 100%;
    min-height: 380px;
  }
}
