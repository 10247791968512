@mixin prosumer-energy-vector-list-theme($theme) {
  prosumer-energy-vector-list {
    .energy-vector-list {
      .mat-icon {
        stroke: $blue !important;
      }

      &.disabled {
        .mat-icon {
          stroke: $disabled-color !important;
        }
      }
    }
  }
}
