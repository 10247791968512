@mixin general-scrollbar-theme($theme) {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: $light-gray;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $light-gray;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
}
