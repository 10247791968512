@mixin prosumer-energy-balance-theme($theme) {
  prosumer-energy-balance {
    .pie-label {
      font-weight: bold !important;
    }

    .chart-legend .legend-labels {
      white-space: inherit !important;
    }
  }
}
