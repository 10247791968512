@mixin custom-mat-progress-theme($theme) {
  .usage-bar {
    --mdc-linear-progress-active-indicator-height: 20px;
    --mdc-linear-progress-track-height: 20px;
    &.normal {
      --mdc-linear-progress-active-indicator-color: #69af23 !important;
    }
    &.threshold {
      --mdc-linear-progress-active-indicator-color: #f18f09 !important;
    }
    &.full {
      --mdc-linear-progress-active-indicator-color: #e10019 !important;
    }
    --mdc-linear-progress-track-color: #cfd8dc !important;
  }
}
