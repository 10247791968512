@mixin prosumer-global-theme($theme) {
  .warning {
    color: $yellow;
  }

  .success {
    color: $green;
  }

  .failure {
    color: $red;
  }

  .prosumer-warning {
    border-radius: 5px;
    padding: 10px;
    background: rgba($yellow, 0.1);
  }

  .eyes-primary {
    color: $blue;
  }

  .eyes-bg-primary {
    background-color: $blue;
  }

  .eyes-border-primary {
    border-color: $blue;
  }

  .eyes-accent {
    color: $orange;
  }

  .eyes-bg-accent {
    background-color: $orange;
  }

  .eyes-border-accent {
    border-color: $orange;
  }

  .eyes-warn {
    color: $red;
  }

  .eyes-bg-warn {
    background-color: $red;
  }

  .eyes-border-accent {
    border-color: $red;
  }

  .eyes-text-gray {
    color: $gray;
  }

  /* Eyes-related styles with theming */
  .eyes-footer {
    background: $background-color;
  }

  .eyes-status-success {
    color: $green;
  }

  .eyes-status-failed {
    color: $red;
  }

  .eyes-status-draft {
    color: $gray;
  }

  .eyes-view-only {
    color: $gray;
  }

  .eyes-status-pending {
    color: $yellow;
  }

  .eyes-status-ongoing {
    color: $dark-blue;
  }

  .eyes-href-bold {
    color: $blue;
    text-decoration: none;
  }

  .eyes-placeholder {
    color: $label-color;
  }

  .eyes-icon-button-disabled .mat-icon {
    color: $disabled-color;
  }

  .eyes-breadcrumbs-container {
    margin-left: 30px;
    color: $blue !important;

    a {
      font-size: 14px !important;
    }
    a,
    .mat-icon {
      color: $blue !important;
    }
  }

  .eyes-charts-container {
    border: 1px solid $foreground-divider-color;
  }

  // most of the propoerties should be !important because
  // we need to overwrite popper.js
  .bubble {
    height: 50px;
    width: 50px;
    border: 3px solid #43425d;
    background-color: #eee;
    border-radius: 50%;
    margin: -30px 0px 0px 70px;
    div {
      text-align: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      overflow-wrap: break-word;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #43425d;
      font-weight: bold;
    }
  }

  .bubble-fade {
    height: 50px;
    width: 50px;
    border: 3px solid rgb(195, 193, 193);
    background-color: lightgray;
    border-radius: 50%;
    margin: -30px 0px 0px 70px;
    div {
      text-align: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      overflow-wrap: break-word;
      align-items: center;
      display: flex;
      justify-content: center;
      color: darkgray;
      font-weight: bold;
    }
  }
}
