@mixin prosumer-stacked-area-chart-theme($theme) {
  prosumer-stacked-area-chart {
    ngx-charts-area-chart-stacked {
      margin-right: 30px;
    }

    .ngx-slider {
      @extend .custom-slider-type2;
    }
  }
}
