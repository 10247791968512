@mixin prosumer-project-list-page-theme($theme) {
  prosumer-project-list-page {
    .prosumer-share-icon .mat-badge-content {
      background: $background-color;
      border: 2px solid $blue;
      align-content: center;
      line-height: normal;
      font-weight: bold;
      font-size: 8px;
      color: $blue;
    }

    .mat-mdc-button-disabled .prosumer-share-icon .mat-badge-content {
      border: 2px solid $disabled-text; //var(--mdc-icon-button-disabled-icon-color);
      color: $disabled-text;
    }
  }
}
