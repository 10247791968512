@use '@angular/material' as mat;
@mixin custom-mat-snackbar-theme($theme) {
  .mat-mdc-snack-bar-container {
    .mat-mdc-snack-bar-action,
    button {
      border-radius: 25px;
    }

    .mat-mdc-snack-bar-action {
      color: $light-gray;
      border: 1px solid $light-gray;
      pointer-events: auto;
    }

    .mat-mdc-simple-snack-bar {
      margin: 5px 0;
    }

    /* Eyes-related snackbar styles */
    &.eyes-snackbar-success,
    &.eyes-snackbar-error,
    &.eyes-snackbar-info {
      @include mat.elevation(10);
      opacity: 1;
      border-radius: 5px;
      pointer-events: none;
    }

    &.eyes-snackbar-success > div {
      background-color: $green;
    }

    &.eyes-snackbar-error > div {
      background-color: $red;
    }

    &.eyes-snackbar-info > div {
      background-color: $orange;
    }
  }
}
