@mixin custom-mat-chip-grid-theme($theme) {
  .mat-mdc-form-field.filterchip {
    * {
      --mat-form-field-container-height: 56px;
    }
    .mat-mdc-form-field-infix {
      min-height: var(--mat-form-field-container-height);
    }
    .mat-mdc-chip-set.mat-mdc-chip-grid {
      // padding: 8px 0;
      padding: 0;

      .mat-mdc-chip.mat-mdc-chip-row .mat-mdc-chip-action-label {
        color: white;
      }

      input {
        padding: 8px 0;
      }
    }
  }
  .mat-mdc-form-field + .mat-mdc-chip-grid {
    .mdc-evolution-chip-set__chips {
      margin: 0px;
    }
    .mat-mdc-chip.mat-mdc-chip-row {
      background-color: #007acd;
      .mat-mdc-chip-action-label {
        color: white;
      }
      .mat-mdc-chip-remove {
        color: white;
        opacity: 0.4;
      }
    }
  }
}
