@mixin prosumer-chart-tooltip-theme($theme) {
  .tooltip {
    padding: 10px 5px 20px;

    .tooltip-item-color {
      display: inline-block;
      height: 12px;
      width: 12px;
      margin-right: 5px;
      color: $smoke-gray;
      border-radius: 3px;
    }

    .tooltip-item-series {
      padding-right: 8px;
    }

    .tooltip-item-value {
      text-align: right;
    }

    .tooltip-item.current {
      font-weight: bolder;
    }

    .tooltip-total {
      border-top: 1px solid $white;
      padding-top: 8px;
      margin-top: 5px;
    }
  }
}
