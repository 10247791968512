@import 'dependencies/helpers.style.scss';
@import '../../node_modules/@oculus/styling/ellipse';

html[data-darkreader-scheme='dark'] {
  ::-webkit-scrollbar-track {
    background: #2e2e2e;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #2e2e2e;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  overflow: hidden;
}

p {
  line-height: 30px;
  margin-bottom: 25px !important;
}

ul li {
  margin-bottom: 15px;
}

table {
  width: 100%;
}

/* Hide spinner in number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Workaround to hidden directive issue conflicting css when host display is block */
[hidden] {
  display: none !important;
}

/* Start - PR-820 Space Optimization */
h2,
p {
  margin-bottom: 10px !important;
}

p {
  line-height: 24px !important;
}

input[type='number'].hide-spinner::-webkit-inner-spin-button,
input[type='number'].hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  line-height: 20px;
}
/* End - PR-820 Space Optimization */

.invisible {
  opacity: 0;
}

.eased {
  transition: ease-in-out 0.3s;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.text-center {
  text-align: center;
}

.soft-border {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
}

.hover-opacity {
  opacity: 0.2;
  transition: ease-in-out 0.3s;
  &:hover {
    transition: ease-in-out 0.3s;
    opacity: 1;
  }
}

.chart-controls {
  margin-bottom: -20px;
  // margin-right: -32px;
}

.storage-dispatch-title {
  padding-left: 32px;
  font-size: 24px;
  font-weight: 300;
}

.oculus-drawer-nav {
  flex-direction: unset !important;
}

.oculus-drawer-content {
  @include ellipse-layout-page();
  height: 100% !important;
}
.mat-drawer-container .mat-drawer {
  width: 278px !important;
  overflow: hidden;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;

  + div + .mat-drawer-content {
    margin-left: 278px !important;
  }
  .mat-nav-list:empty {
    display: none;
  }
  .mat-mdc-list-item .mdc-list-item__content {
    padding: 0 26px;
  }
  &:not(.mat-drawer-opened) {
    width: 90px !important;
    + div + .mat-drawer-content {
      margin-left: 90px !important;
    }
    .mat-mdc-list-item .mat-icon + span {
      display: none;
    }
    .mat-mdc-list-item .mdc-list-item__content {
      padding: 0 38px 0 26px;
    }
  }
  div.oculus-drawer-header img.ng-star-inserted {
    padding-bottom: 20px;
    padding-top: 33.5px;
    height: 48px;

    &.oculus-drawer-logo {
      padding-right: 65.97px;
      padding-left: 53.97px;
      width: 158.06px;
    }
  }
  .oculus-drawer-footer img {
    &.oculus-drawer-logo {
      padding-bottom: 37.15px;
      padding-right: 79px;
      padding-left: 76px;
      height: 48.85px;
      width: 123px;
    }
    &.oculus-drawer-logo-mini {
      padding-left: 26px;
      padding-right: 30px;
      padding-bottom: 38.1px;
    }
  }
}
.oculus-drawer-header {
  min-height: 0px;
  max-height: 35px;
}
.drawer-nav-tooltip {
  margin: 14px 0px;
  color: black;
}
button.oculus-drawer-toggle.mat-mdc-icon-button {
  position: absolute;
  z-index: 100;
  top: 0px;
  right: -5px;
  width: 42px;
  height: 42px;
  padding: 9px;
}

oculus-header #oculus-header {
  z-index: 101;
}

oculus-header .oculus-header-custom path:empty {
  display: inline-block;
  visibility: visible;
}

oculus-drawer .mat-mdc-list-item.oculus-active-link svg path {
  fill: currentColor;
}

.ask-support-button {
  *,
  > .mat-button-wrapper {
    pointer-events: none;
  }
}

.notifications-list + div {
  display: none !important;
}

mat-icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.no-click {
  pointer-events: none;
}

.spinner {
  span,
  mat-icon {
    @extend .invisible;
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top-color: #007acd;
  animation: spinner 1s linear infinite;
}

.default-content:empty {
  padding: 4px 15px;
  margin-bottom: 10px;
  margin-top: 10px;

  &::after {
    content: '-';
  }
}

.white-space-unset {
  white-space: unset !important;
}

// targetting sankey text for dark mode compatibility
.node-label {
  text-shadow: none !important;
}

.paginator-dark {
  .mat-mdc-paginator-icon {
    fill: white;
  }
  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: rgba($color: white, $alpha: 0.1);
  }
}
