@use '@angular/material' as mat;
@mixin eyes-tooltip-theme($theme) {
  eyes-tooltip .tooltip {
    background-color: $light-gray;
    // @include mat-mat.elevation(4);
    @include mat.elevation(4);
  }

  eyes-tooltip-anchor mat-icon {
    cursor: help;
    margin-top: 7px !important;
    color: $tooltip-anchor-color;
  }

  eyes-tooltip-anchor.attached-component mat-icon {
    margin-top: 3.5px !important;
    font-size: 18px !important;
  }
}
