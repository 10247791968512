@mixin custom-mat-toolbar-theme($theme) {
  .mat-toolbar {
    background-color: $dark-blue !important;
    color: $white;

    .mat-toolbar-row {
      height: auto !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
