@mixin custom-mat-menu-theme($theme) {
  .mat-mdc-menu-panel.eyes-notification {
    width: 500px !important;
    max-width: 600px !important;
    max-height: 320px !important;
  }

  .mat-mdc-menu-panel.scenario-actions-expert-menu {
    min-width: 200px !important;
    // min-height: 440px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    border: 2px transparent !important;
    border-radius: 4px !important;
    overflow-y: hidden !important;
  }

  .mat-mdc-menu-panel.scenario-actions-biz-menu {
    min-width: 200px !important;
    min-height: 330px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    border: 2px transparent !important;
    border-radius: 4px !important;
    overflow-y: hidden !important;
  }

  .mat-mdc-menu-panel {
    div.scenario-action-mat-menu-group {
      padding-top: 6px !important;
      padding-bottom: 2px !important;
      font-weight: bold !important;
      font-size: medium !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
    span.scenario-action-active {
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }

  .mat-mdc-menu-item.scenario-action-link-active {
    padding: 0px 16px !important;
    line-height: 40px !important;
    height: 40px !important;
    .mat-icon {
      color: #007acd !important;
    }
  }

  .mat-mdc-menu-item.scenario-action-inactive {
    padding: 0px 16px !important;
    line-height: 40px !important;
    height: 40px !important;
    .mat-icon {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .mat-mdc-menu-panel.action-menu-list {
    .mat-mdc-menu-item {
      line-height: 30px;
      height: auto;
    }
    .remove {
      color: $red;
    }
  }

  // * {
  //   --mat-menu-item-label-text-size: 14px;
  // }
}
