@mixin custom-mat-table-theme($theme) {
  .mat-mdc-table {
    a {
      color: $blue;
      text-decoration: none;
    }

    .mat-mdc-row {
      transition: $transition-bg;
      align-items: normal;
      &:nth-child(even) {
        background: $light-gray;
      }
      &:hover {
        background: $hover-color;
      }
    }

    .mat-mdc-header-cell {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.54);
    }

    .mat-mdc-header-cell mat-icon {
      margin-top: 9px !important;
    }

    .mat-mdc-cell {
      font-size: 14px;
      font-weight: normal;
      align-items: center;
      // &:not(
      //     .cdk-column-runHistory:not(:empty),
      //     .cdk-column-actions,
      //     .cdk-column-input,
      //     .cdk-column-log,
      //     .cdk-column-output,
      //     .cdk-column-coordinates
      //   ) {
      //   margin-top: 10px;
      //   margin-bottom: 10px;
      // }

      &.cdk-column-actions,
      &.cdk-column-runHistory {
        ngx-skeleton-loader {
          margin-top: 10px !important;
          margin-bottom: 10px !important;
        }
      }

      .eyes-status-failed,
      .eyes-status-success,
      .eyes-status-ongoing,
      .eyes-status-pending,
      .eyes-status-draft {
        align-items: normal !important;
      }

      div.list-reference {
        overflow: hidden;
        text-overflow: ellipsis !important;
        word-wrap: normal !important;
      }
      .error {
        color: $red;
      }
      .success {
        color: $green;
      }
    }
  }

  /* Start - PR-820 Space Optimization */
  eyes-table,
  .eyes-table {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      padding: 2px 15px !important;
    }

    .mat-mdc-header-row,
    .mat-mdc-row {
      min-height: 35px !important;
      z-index: 9 !important;
    }

    mat-form-field .mat-mdc-text-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
  /* End - PR-820 Space Optimization */
}
