@mixin prosumer-energy-vectors-theme($theme) {
  prosumer-energy-vectors {
    .prosumer-icon-container .mat-icon,
    .mat-mdc-list-item .mat-icon {
      stroke: $gray !important;
    }

    .prosumer-icon-container:hover:not(.disabled),
    .prosumer-icon-container:focus:not(.disabled) {
      border-color: $gray !important;
      background-color: $hover-color !important;
      outline-width: 0;
    }

    .prosumer-icon-container.selected:not(.disabled) {
      border-color: $blue !important;
    }
  }
}
