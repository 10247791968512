@mixin prosumer-ev-toggle-group-theme($theme) {
  prosumer-ev-toggle-group {
    $selected-color: $blue;

    h4,
    h3 {
      margin-bottom: 0 !important;
    }
    .mat-button-toggle-group {
      border: none !important;
      .mat-button-toggle,
      .mat-button-toggle-ripple {
        border-radius: 10px !important;
      }
      .mat-mdc-icon-button {
        height: 25px;
        width: 25px;
        padding: 0;
      }
      .mat-button-toggle-label-content {
        line-height: 36px !important;
        position: unset !important;
      }
      .mat-button-toggle {
        border: 2px solid transparent !important;
        color: $black !important;

        .mat-icon {
          stroke: $black !important;
        }
        &:hover:not(.mat-button-toggle-checked, .is-view-only) {
          background-color: $hover-color !important;
          border-color: $gray !important;
        }
        &.mat-button-toggle-checked::before {
          color: $selected-color !important;
        }
        &.mat-button-toggle-checked {
          border-color: $selected-color !important;
          color: $selected-color !important;
          background: none !important;
          background-image: linear-gradient(
            -45deg,
            rgba($selected-color, 0.08),
            rgba($selected-color, 0.03),
            rgba($selected-color, 0.08)
          ) !important;

          &:hover {
            background-color: transparent !important;
          }

          .mat-icon {
            stroke: $selected-color !important;
          }
        }

        &.is-unsaved-ev {
          background-color: #ffd1d136 !important;
          border: 1px solid red !important;
        }

        h4 {
          font-size: 14px !important;
        }
      }
      .mat-button-toggle-focus-overlay {
        border-bottom: 0 !important;
      }
      .mat-button-toggle-checked {
        &::before {
          content: 'MAIN';
          position: absolute;
          left: 5px;
          top: 0;
          font-size: 10px;
          pointer-events: none;
        }
      }
    }
  }
}
