@mixin prosumer-simulation-results-theme($theme) {
  prosumer-simulation-results {
    .mat-mdc-tab-body-content {
      height: 100%;
      overflow: hidden !important;
    }

    prosumer-storage-monthly-dispatch,
    prosumer-storage-daily-dispatch {
      .chart-legend {
        display: inline-block;
        padding: 0;
        width: auto !important;

        .legend-title {
          white-space: nowrap;
          overflow: hidden;
          margin-left: 10px;
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: normal;
        }

        ul,
        li {
          padding-left: 5px;
          margin: 0;
          list-style: none;
        }

        .horizontal-legend {
          li {
            display: inline-block;
          }
        }

        .legend-wrap {
          width: calc(100% - 10px);
        }

        .legend-labels {
          line-height: 85%;
          list-style: none;
          text-align: left;
          float: left;
          width: 100%;
          border-radius: 3px;
          overflow-y: auto;
          overflow-x: hidden;
          white-space: nowrap;
          background: none;
        }

        .legend-label {
          cursor: pointer;
          font-size: 90%;
          margin: 8px;
          color: $gray;

          &:hover {
            color: #000;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            transition: 0.2s;
          }

          .active {
            .legend-label-text {
              color: #000;
            }
          }
        }

        .legend-label-color {
          display: inline-block;
          height: 15px;
          width: 15px;
          margin-right: 5px;
          color: #5b646b;
          border-radius: 3px;
        }

        .legend-label-text {
          display: inline-block;
          vertical-align: top;
          line-height: 15px;
          font-size: 12px;
          width: calc(100% - 15px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .legend-title-text {
          vertical-align: bottom;
          display: inline-block;
          line-height: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .chart-legend {
      .legend-labels {
        background: none;
      }

      .legend-label {
        color: $gray;
      }

      .legend-title {
        font-weight: normal;
      }
    }
  }
}
