.eyes-maximize-width {
  width: 100%;
}

.eyes-form-input-width {
  width: 25%;
}

.eyes-width-limiter {
  max-width: 550px;
}

.eyes-spacer {
  margin-bottom: 20px !important;
}

.eyes-spacer-half {
  margin-bottom: 10px !important;
}

.eyes-spacer-pad {
  padding-bottom: 20px !important;
}

.eyes-spacer-pad-half {
  padding-bottom: 10px !important;
}

.eyes-spacer-top-x2 {
  margin-top: 40px !important;
}

.eyes-spacer-top {
  margin-top: 20px !important;
}

.eyes-spacer-top-half {
  margin-top: 10px !important;
}

.eyes-clickable {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  color: #007acd;
}

.eyes-disabled-link {
  pointer-events: none;
  cursor: default;
}

.eyes-overflow-auto {
  overflow: auto;
}

.eyes-inline {
  display: inline;
}

.eyes-justify {
  text-align: justify;
}

.eyes-fit-content {
  width: fit-content;
}

.eyes-remove-margin {
  margin: 0 !important;
}

.eyes-absolute-top {
  position: absolute !important;
  top: 0 !important;
}

.eyes-table-message {
  margin-left: 20px;
}

/* Start - PR-820 Space Optimization */
.eyes-width-fit {
  .tooltip {
    max-width: fit-content !important;
  }
}
/* End - PR-820 Space Optimization */

.eyes-error {
  font-size: 75% !important;
  margin-bottom: 10px !important;
}

.eyes-table-sticky {
  overflow: auto;
}

.eyes-inline-icon-button {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}

.eyes-gdpr-dialog {
  .mat-headline-6.mat-mdc-dialog-title {
    display: none;
  }

  .mat-body.mat-mdc-dialog-content {
    padding-top: 24px;
    padding-bottom: 30px;
  }

  .mat-mdc-dialog-actions {
    place-content: center center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 30px;
  }
}

.eyes-charts-container {
  border-radius: 5px;
  margin-top: 3px;
  padding: 24px;
}

.align-start {
  text-align: start;
  * {
    text-align: start;
  }
}

.align-end {
  text-align: end;
  * {
    text-align: end;
  }
}

.eyes-bold-text {
  font-weight: 600;
}

.marginless-header {
  margin-bottom: 0 !important;
}

.color-warn {
  color: #db3735;
}
