@use '@angular/material' as mat;
@mixin prosumer-solar-tab-theme($theme) {
  prosumer-solar-tab {
    .create-mode {
      .mat-step-header:not(:first-child),
      .mat-stepper-horizontal-line {
        opacity: 0.3;
      }
    }

    .read-mode {
      .mat-step-header:last-child,
      .mat-stepper-horizontal-line:nth-last-child(2) {
        display: none;
      }
    }

    .saving {
      .mat-step-header,
      .mat-stepper-horizontal-line {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    .mat-step-icon {
      background-color: $orange !important;
    }

    .mat-horizontal-stepper-header-container {
      flex-wrap: wrap;
    }

    .mat-step-header {
      border-radius: 10px;
      border: 2px solid transparent;
    }

    .mat-step-header.cdk-program-focused,
    .mat-step-header[aria-selected='true'] {
      background-color: $white;
      border-color: $orange;
    }

    .mat-step-header[ng-reflect-active='false'] {
      cursor: default;
      background-color: transparent !important;
      .mat-step-header-ripple {
        display: none;
      }
      .mat-step-icon {
        background-color: $disabled-text !important;
      }
    }

    .mat-horizontal-stepper-header-container {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .mat-mdc-card {
      .mat-mdc-progress-bar {
        border-radius: 5px;
        top: -10px;
      }

      box-shadow: none !important;
      background: transparent !important;
      padding: 0 10px !important;
    }

    .mat-expansion-panel {
      @include mat.elevation(2);
      margin-bottom: 20px;
      border-radius: 5px;

      .mat-expansion-panel-header {
        margin-bottom: 0 !important;
        padding: 5px 15px !important;
        background: $hover-color;
        &.mat-expanded {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:not(.mat-expanded) {
          border-radius: 5px;
        }

        .mat-expansion-panel-header-title {
          font-size: 16px !important;
          font-weight: 600 !important;
          color: $text-color;
        }
      }

      .mat-expansion-panel-header:focus {
        background: $hover-color;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 18px 20px 20px;
        }
      }
    }

    eyes-stepper-nav {
      margin-top: 25px !important;
    }
  }
}
