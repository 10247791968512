@mixin prosumer-series-tooltip-theme($theme) {
  prosumer-series-tooltip {
    .prosumer-tooltip {
      padding: 10px 5px 20px;

      .prosumer-tooltip-item-color {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        color: $smoke-gray;
        border-radius: 3px;
      }

      .prosumer-tooltip-item-series {
        padding-right: 8px;
      }

      .prosumer-tooltip-item-value {
        text-align: right;
      }

      .prosumer-tooltip-item.current {
        font-weight: bolder;
      }

      .prosumer-tooltip-total {
        border-top: 1px solid $white;
        padding-top: 8px;
        margin-top: 5px;
      }
    }
  }
}
