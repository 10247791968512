@mixin oculus-card-item-theme($theme) {
  oculus-card-item {
    .mat-mdc-card {
      transition: background-color 200ms ease-out;
      background-color: map.get($grays, 100) !important;
    }
  }
}
@mixin oculus-components-theme($config-or-theme) {
  @include oculus-drawer-theme($config-or-theme);
  @include oculus-header-theme($config-or-theme);
  @include oculus-status-text-theme($config-or-theme);
  @include oculus-notifications-theme($config-or-theme);
  @include oculus-shared-user-table-theme($config-or-theme);
  @include oculus-card-item-theme($config-or-theme);
}
@mixin oculus-drawer-theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  oculus-drawer {
    .mat-mdc-drawer {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    }
    .mat-mdc-drawer .oculus-drawer-nav {
      .oculus-drawer-toggle,
      .mat-mdc-list-item .mat-mdc-icon {
        color: mat.get-color-from-palette($foreground, icon);
      }
      .mat-mdc-list-item {
        border-left: 2px solid transparent;
      }
      .mat-mdc-list-item.oculus-active-link {
        background-color: mat.get-color-from-palette($background, hover);
        border-left: 2px solid mat.get-color-from-palette($primary);
        .mat-mdc-icon {
          color: mat.get-color-from-palette($primary);
        }
      }
    }
    .mat-drawer-content {
      background-color: $white;
    }

    .oculus-drawer-toggle {
      margin: 0 6px !important;
      .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
        background-color: var($blue-bolt);
      }

      .mat-icon {
        color: $gray-600;
      }
    }
    .oculus-drawer-nav {
      .mat-mdc-list-item.oculus-active-link {
        background-color: $hover;
        border-left: 2px solid mat.get-color-from-palette($primary);
        .mat-icon {
          color: mat.get-color-from-palette($primary);
        }
      }
    }

    .mdc-list-item {
      .mat-icon {
        color: $gray-600;
        overflow: initial;
      }
    }
  }
}
@mixin oculus-header-theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $primary: map.get($config, primary);
  oculus-header {
    $zValue: 2;
    $color: #9999;
    $opacity: 14;
    height: fit-content;

    .oculus-header-content {
      padding: 2rem 1.5rem 0.5rem;
    }

    .oculus-header-scroll {
      border-bottom: 1px;
      position: sticky;
      z-index: 100;
      background-color: white !important;
      box-shadow: 0px 2px 1px -2px #999999, 0px -9px 0px 0px #999999,
        0px -1px 5px 0px #999999 !important;
      transition-timing-function: ease-in;
      transition: 0.25s;

      .oculus-header-content {
        padding: 1rem 1.5rem 0.5rem;
      }
    }

    .oculus-header-brand {
      font-weight: 600;
      font-size: 24px;
    }

    .oculus-header-brand--primary {
      color: mat.get-color-from-palette($primary);
    }

    .oculus-header-brand--gradient {
      @include ellipse-typography-gradient-font();
    }

    .oculus-header-custom :empty {
      display: none;
      visibility: hidden;
    }
    .oculus-header-content :empty {
      display: none;
      visibility: hidden;
    }
    .oculus-header-sub-title :empty {
      display: none;
      visibility: hidden;
    }
    .oculus-header-brand :empty {
      display: none;
      visibility: hidden;
    }
  }
}
@mixin oculus-managed-card-list-theme($theme) {
  oculus-managed-card-list {
    .mat-mdc-card:hover,
    .mat-mdc-card:focus {
      transition: background-color 50ms ease-in;
      background-color: map.get($grays, 200);

      .mat-mdc-card-actions .mat-mdc-icon-button {
        transition: opacity 50ms ease-in;
        opacity: 1;
      }

      outline: none;
    }

    .oculus-no-data {
      border-radius: 0.25rem;
      background-color: map.get($grays, 100) !important;
    }

    .mat-mdc-card-actions .mat-mdc-icon-button:not(.cdk-focused, .deleting) {
      transition: opacity 200ms ease-out;
      opacity: 0;
    }

    .mat-mdc-card:hover,
    .mat-mdc-card:focus {
      transition: background-color 50ms ease-in !important;
      background-color: map.get($grays, 200) !important;

      .mat-mdc-card-actions .mat-mdc-icon-button {
        transition: opacity 50ms ease-in !important;
        opacity: 1 !important;
      }

      outline: none !important;
    }
  }
}
@mixin oculus-meta-table-theme($theme) {
  oculus-meta-table {
    .mat-mdc-table {
      background-color: transparent;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: map.get($grays, 50);
    }

    .mat-mdc-row:hover {
      background-color: map.get($grays, 100);
    }

    .oculus-expandable-rows {
      .mat-mdc-row:nth-child(4n-1),
      .mat-mdc-row:nth-child(4n-2) {
        background-color: $gray-50 !important;
      }

      .mat-mdc-row:nth-child(4n-3),
      .mat-mdc-row:nth-child(4n-4) {
        background-color: $white !important;
      }

      .mat-mdc-row.oculus-row-expanded,
      .mat-mdc-row.oculus-details-row-expanded {
        background-color: map.get($blues, 100) !important;
      }

      .mat-mdc-row:not(.oculus-details-row-expanded):hover {
        background-color: map.get($grays, 100) !important;
      }

      .mat-mdc-row.oculus-row-expanded:hover {
        background-color: map.get($blues, 200) !important;
      }

      .oculus-row-expanded,
      .oculus-details-row-expanded {
        border-left-color: map.get($blues, 600);
      }

      .oculus-row-expanded {
        border-bottom: 1px solid map.get($blues, 200) !important;
        .mat-icon {
          font-family: 'Material Icons';
        }
      }
    }

    .mat-mdc-sort-header-content {
      text-align: left;
      align-content: flex-start;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      background-color: transparent;
      border: none !important;
      padding: 0.5rem 1rem !important;
      text-align: left;
    }

    .cdk-column-actions,
    .mat-column-actions {
      padding: 0 1rem !important;
    }

    .mat-mdc-header-row,
    .mat-mdc-row {
      padding: 0.5rem 1rem;
      align-items: start;
      border-left: 2px solid transparent;
    }

    .mat-mdc-header-row {
      text-transform: uppercase;
      border-bottom: 1px solid map.get($grays, 200) !important;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: map.get($grays, 50) !important;
    }

    .mat-mdc-row:hover {
      background-color: $hover !important;
    }

    .mat-mdc-header-cell {
      color: map.get($grays, 500) !important;
      text-transform: uppercase;
      font-size: smaller;
      align-self: baseline !important;
    }

    .oculus-row-disabled {
      background-color: map.get($grays, 300) !important;
      cursor: default !important;
      pointer-events: none;
      mat-icon,
      mat-cell {
        color: map.get($grays, 500);
      }
    }

    .oculus-input-text {
      font-size: smaller;
      margin: 0 !important;
    }

    .oculus-link {
      color: map.get($blues, 600);
    }

    .oculus-link:hover {
      text-decoration: underline;
    }
  }
}
@mixin oculus-notifications-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  oculus-notifications {
    .mdc-list-item__content,
    .mdc-list-item__primary-text {
      overflow: visible !important;
    }
  }

  /* Overlays */
  .oculus-notifications-overlay,
  .oculus-notifications-container {
    width: 100%;
    max-width: 500px;
  }

  .oculus-notifications-overlay {
    background-color: #ffffff;
  }

  .oculus-notifications-container {
    oculus-table {
      .mat-mdc-table {
        max-height: 400px;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .mat-mdc-header-row {
        display: none;
      }

      .mat-mdc-row {
        border-bottom-width: 0;
      }

      .mat-mdc-cell {
        padding: 1rem 0.25rem;
      }
    }

    .oculus-empty-row {
      padding: 0.5rem 0 !important;
      color: map.get($grays, 600);
    }
  }

  .oculus-avatar {
    box-shadow: 0 0 0 2px $white;
    background-color: mat.get-color-from-palette($primary-palette);
    color: $white;

    $dim: 40px;

    overflow: visible;
    background-size: 81%;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: $dim;
    min-height: $dim;
    width: $dim;
    height: $dim;

    font-size: 1rem;
    text-transform: uppercase;
  }
}
@mixin oculus-shared-user-table-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  oculus-shared-user-table {
    oculus-table {
      mat-header-row {
        display: none;
      }

      mat-row {
        border-bottom-width: 0;
      }
    }

    .oculus-empty-row {
      padding: 0 !important;
      color: map-get($grays, 600);
    }

    .shared-user-avatar {
      box-shadow: 0 0 0 2px $white;
      background-color: mat.get-color-from-palette($primary-palette);
      color: $white;

      $dim: 40px;

      overflow: visible;
      background-size: 81%;
      border-radius: 50%;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      min-width: $dim;
      min-height: $dim;
      width: $dim;
      height: $dim;

      font-size: 1rem;
      text-transform: uppercase;
    }

  }
}
@mixin oculus-status-text-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $oculus-primary: map.get($color-config, 'primary');
  $oculus-warn: map.get($color-config, 'warn');

  $orange: mat.get-color-from-palette($oculus-warn, warning);
  $red: mat.get-color-from-palette($oculus-warn, danger);
  $blue: mat.get-color-from-palette($oculus-primary);
  $gray: map-get($grays, 600);

  oculus-status-text {
    .oculus-status-text {
      &-error {
        color: $red;
      }

      &-warn {
        color: $orange;
      }

      &-info,
      &-suffix {
        color: $gray;
      }

      &-label {
        color: map-get($grays, 500);
      }

      &-suffix {
        font-weight: 300;
      }

      &-link {
        color: $blue;
      }
    }

    .mat-mdc-icon {
      $dim: 18px;

      font-size: $dim;
      height: $dim;
      width: $dim;
    }
  }
}
@mixin oculus-table-theme($theme) {
  oculus-table {
    .mat-mdc-table {
      background-color: transparent;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: map.get($grays, 50);
    }

    .mat-mdc-row:hover {
      background-color: map.get($grays, 100);
    }

    .mat-mdc-sort-header-content {
      text-align: left;
      align-content: flex-start;
    }

    .mat-mdc-header-cell {
      color: map.get($grays, 500);
      text-transform: uppercase;
      font-size: smaller;
      align-self: baseline !important;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding: 0.5rem 1rem !important;
      background-color: transparent;
      border: none !important;
    }

    .mat-mdc-header-row {
      border-bottom: 1px solid map.get($grays, 200) !important;
    }

    .mat-mdc-row {
      padding: 0.5rem 1rem !important;

      &:nth-child(even) {
        background-color: map.get($grays, 50) !important;
      }
      &:hover {
        background-color: $hover !important;
      }
    }

    .cdk-column-actions,
    .mat-column-actions {
      padding: 0 1rem !important;
    }

    .oculus-input-text {
      font-size: smaller;
      margin: 0 !important;
    }
  }
}
@mixin oculus-yearly-forecast-theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $primary: map.get($config, primary);
  oculus-yearly-forecast {
    .cascade-toggle {
      padding-left: 12px;
      font-size: 12px;
    }

    .forecast-hint {
      font-size: 12px;
      color: #aaa;
      font-weight: 300;
    }

    .yearly-forecast-hint {
      font-size: 12px;
      color: #aaa;
      font-weight: 300;
    }
  }
}
@mixin oculus-message-hub-theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);

  $highlight-bg: #e7eefc;
  $highlight-border: #c0d4f9;

  oculus-message-hub {
    .oculus-message-hub {
      &-posts-count {
        font-size: small;
      }
    }
  }

  oculus-message-hub-posts,
  oculus-message-hub-post,
  oculus-message-hub-post-loader {
    .mat-mdc-line {
      white-space: normal !important;
      line-height: 1.5;
    }

    .mat-mdc-list-item {
      height: auto !important;
    }

    .mat-mdc-list-item-content {
      padding: 1rem !important;
      display: block !important;
    }

    .oculus-message-hub-post {
      width: calc(100% - 0.75rem) !important;
      margin-bottom: 1rem;

      background-color: $gray-100;
      border: 1px solid $gray-200;
      border-radius: 4px;

      &-highlight {
        background-color: $highlight-bg;
        border-color: $highlight-border;
      }

      &-message {
        margin-bottom: 0.5rem !important;
        font-size: 14px !important;
        word-break: break-all;
      }

      &-created-by,
      &-created-date {
        font-size: 12px !important;
        text-align: right;
      }
    }
  }
}
