@mixin custom-mat-dialog-theme($theme) {
  .mat-mdc-dialog-container {
    padding: 0 !important;

    .mat-mdc-dialog-component-host {
      .mat-mdc-dialog-title,
      .mat-mdc-dialog-content {
        padding: 0 8px;
      }

      form {
        padding: 0 24px;

        & > .mat-mdc-dialog-title,
        & > .mat-mdc-dialog-content {
          padding: 0 8px;
        }
        & > div.mat-mdc-dialog-content {
          padding-top: 4px;
        }

        form {
          padding: 0;
        }
      }
    }

    // h1.mat-mdc-dialog-title,
    // h2.mat-mdc-dialog-title {
    //   padding: 0 8px;

    //   & + div.mat-mdc-dialog-content {
    //     padding-top: 4px;
    //   }
    // }

    /* Start - PR-820 Space Optimization */
    div.mat-mdc-dialog-content {
      padding: 0 8px;

      .mat-expansion-panel-header-title {
        padding-top: 5px;
      }
    }
    /* End - PR-820 Space Optimization */

    div.mat-expansion-panel-body {
      padding-bottom: 0;
    }

    div.mat-mdc-dialog-actions {
      padding: 0 8px 25px;
    }
  }
}
