@mixin prosumer-usages-theme($theme) {
  prosumer-usages {
    .chart-legend {
      .legend-labels {
        background: none;
      }

      .legend-label {
        color: $gray;
      }

      .legend-title {
        font-weight: normal;
      }
    }
  }
}
