@mixin prosumer-energy-vector-select-theme($theme) {
  prosumer-energy-vector-select {
    eyes-icon-button-toggle,
    prosumer-icon-button-toggle {
      .icon-button-toggle {
        margin-top: 0 !important;
      }

      .icon-button-toggle .mat-icon {
        stroke: $gray !important;
        svg {
          height: 60px;
        }
      }

      .icon-button-toggle:hover:not(.disabled):not(.readonly),
      .icon-button-toggle:focus:not(.disabled):not(.readonly) {
        border-color: $gray;
        background-color: $hover-color;
        outline-width: 0;
      }

      .icon-button-toggle.selected:not(.disabled) {
        $selected-color: $blue;

        border-color: $selected-color;
        color: $selected-color;
        background-color: transparent !important;
        background-image: linear-gradient(
          -45deg,
          rgba($selected-color, 0.08),
          rgba($selected-color, 0.03),
          rgba($selected-color, 0.08)
        ) !important;

        .mat-icon {
          stroke: $selected-color !important;
        }

        &:hover,
        &:focus {
          border-color: $selected-color;
        }
      }

      .icon-button-toggle.selected.disabled {
        border-color: $disabled-color;
        box-shadow: none;
      }

      .icon-button-toggle.disabled {
        color: $disabled-color;

        .mat-icon {
          stroke: $disabled-color !important;
        }
      }

      .icon-button-toggle.disabled,
      .icon-button-toggle.readonly {
        pointer-events: none;
      }
    }
  }
}
