@use '@angular/material' as mat;
@mixin custom-mat-card-theme($theme) {
  .mat-mdc-card {
    @include mat.elevation(1);
    padding: 24px;

    .mat-mdc-card-header {
      .mat-mdc-card-header-text {
        margin: 0;
      }
    }

    &.notifications-parent {
      &.notif-card {
        background-color: $hover-color !important;
      }

      .notif-default {
        box-shadow: none !important;
        border: 1px solid transparentize($blue, 0.7);
      }

      .disable-link {
        color: $hover-color;
      }

      .active-link {
        color: $blue;
      }
    }
  }

  .mat-mdc-card-avatar {
    margin-right: 10px !important;
  }

  /* Start - PR-820 Space Optimization */
  .mat-mdc-card {
    padding: 16px !important;
  }
  /* End - PR-820 Space Optimization */
}
