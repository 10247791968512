@mixin prosumer-main-output-theme($theme) {
  prosumer-main-output {
    ngx-charts-area-chart-stacked,
    ngx-charts-bar-vertical-stacked {
      margin-right: 30px;

      .legend-labels {
        margin-left: 10px !important;
        min-width: 180px;
      }
    }
  }
}
