@mixin prosumer-time-form-theme($theme) {
  prosumer-time-form {
    .mat-mdc-form-field-error {
      @extend .mat-caption !optional;
    }
    .ngx-slider {
      @extend .custom-slider-type1;
    }
    .mat-button-toggle-group {
      max-width: 785px;
      cursor: default !important;
      border: none;
      margin-bottom: 10px;
      .mat-button-toggle:hover {
        background: $hover-color !important;
      }
      .mat-button-toggle {
        cursor: pointer !important;
        font-weight: lighter !important;
      }
      .mat-button-toggle-checked {
        color: $blue !important;
        background: $white !important;
        font-weight: bolder !important;
      }
      .mat-button-toggle-disabled {
        background: $hover-color !important;
        pointer-events: none !important;
        color: $disabled-text !important;
        cursor: default !important;
      }
      .mat-button-toggle,
      .mat-button-toggle + .mat-button-toggle {
        border-left: none !important;
      }
    }
  }
}
