@mixin prosumer-yearly-chart-input-theme($theme) {
  prosumer-yearly-chart-input {
    &:has(.hide-empty-subscript):has(
        .mat-mdc-form-field-subscript-wrapper div:empty
      ) {
      margin-bottom: var(--mat-form-field-hide-show-subscript-margin);
    }

    .mdc-notched-outline__notch {
      --mat-mdc-icon-suffix-count: 1 !important;
    }

    .mat-icon.prosumer-yearly-input-tooltip-anchor,
    .mat-icon.prosumer-yearly-input-tooltip-anchor-delete {
      $dim: 20px;
      width: $dim;
      height: $dim;
      font-size: $dim;
      margin-right: 3px;
      display: inline !important;
      cursor: pointer;
    }

    .mat-mdc-form-field-icon-suffix {
      max-height: 100%;
      margin-right: 8.5px;
      flex-direction: row;
      box-sizing: border-box;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      display: flex;

      .mat-icon {
        padding: 0;
        margin-right: 3px;
      }
    }
  }
}
