@mixin custom-mat-icon-theme($theme) {
  .mat-icon.large {
    height: 300px;
    width: 300px;
    font-size: 300px;
  }

  // Apply washout color to tooltip anchor in input
  .mat-icon.eyes-input-tooltip-anchor {
    cursor: help;
    color: $tooltip-anchor-color;
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
    display: inline !important;
  }

  // Apply to display tooltip on custom icon
  .mat-icon.status-icon {
    position: relative;
    z-index: 1;
    svg {
      position: relative;
      z-index: -1;
    }
  }
}
