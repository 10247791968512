@use '@angular/material' as mat;

$prosumer-typography: mat.m2-define-typography-config(
  $font-family: 'Lato, monospace',
  $body-1:
    mat.m2-define-typography-level(
      $letter-spacing: normal,
      $font-size: 14px,
    ),
  $button:
    mat.m2-define-typography-level(
      $letter-spacing: normal,
      $font-size: 14px,
    ),
);
// $input:
//   mat.define-typography-level(
//     $font-size: 14px,
//   ),

/* The following Can be added for custom typography */
// $display-4: mat-typography-level(56px, 56px, 700),
// $display-3: mat-typography-level(48px, 56px, 900),
// $display-2: mat-typography-level(36px, 42px, 700),
// $display-1: mat-typography-level(28px, 32px, 700),
// $headline: mat-typography-level(28px, 36px, 700),
// $title: mat-typography-level(22px, 28px, 900),
// $subheading-2: mat-typography-level(22px, 32px, 300),
// $subheading-1: mat-typography-level(18px, 24px, 900),
// $body-1: mat-typography-level(18px, 30px, 400),
// $body-2: mat-typography-level(18px, 30px, 900),
// $caption: mat-typography-level(14px, 28px, 400),
// $button: mat-typography-level(14px, 36px, 700),
// $input: mat-typography-level(1, 1, 400)

@include mat.all-component-typographies($prosumer-typography);
