$text: #212121;
$logo: #00aaff;

// PRIMARY
$blue-corporate: #009de9;
$white: #ffffff;
$gradient: linear-gradient(135deg, $logo 0.89%, #23d2b5 94.05%);

// SECONDARY
$purple: #1f106d;
$red-mandy: #e74a50;
$orange-crusta: #f78a31;
$yellow-bright-sun: #fbdb3c;
$green-java: #6ed2b1;

// ADDITIONAL
$blue-venice: #005288;
$green-pine: #007873;
$violet-eggplant: #910f7d;
$blue-rhino: #2b485c;
$cerise: #e62b87;
$green-riogrande: #becd00;
$violet: #552382;
$blue-bigstone: #0f1e29;
$green-fun: #69af23;
$yellow: #faca08;
$blue-allports: #0b74c0;

// ACCENT
$accent-red: #cc0033;
$accent-orange: #e07602;
$accent-green: #009934;

// COLLECTIONS
$graph-analysis: (
  $logo,
  $purple,
  $green-java,
  $violet-eggplant,
  $yellow-bright-sun,
  $green-pine,
  $red-mandy
);

// MAPS
$grays: (
  900: #212121,
  800: #424242,
  700: #616161,
  600: #757575,
  500: #9e9e9e,
  400: #bdbdbd,
  300: #e0e0e0,
  200: #eeeeee,
  100: #f5f5f5,
);

$blues: (
  900: #0b2d4e,
  800: #004478,
  700: #005aa0,
  600: #007acd,
  500: #3c8cde,
  400: #7fadef,
  300: #c0d4f9,
  200: #e7eefc,
  100: #f6f8fd,
);
