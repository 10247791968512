@mixin prosumer-efficiency-matrix-theme($theme) {
  prosumer-efficiency-matrix {
    .efficiency-placeholder {
      border-color: $disabled-color !important;
    }

    prosumer-menu-button {
      .mat-mdc-button {
        text-decoration: none;
        color: $blue;
        border: 2px transparent;
        border-radius: 10px;
        height: 49.3px;
        width: 170px;
      }
    }
  }

  .prosumer-menu-button-panel {
    .mat-icon {
      stroke: $black;
    }
  }
}
