@mixin prosumer-equipment-theme($theme) {
  prosumer-equipment {
    .mat-button-toggle-group {
      @extend .custom-button-toggle-group;
    }

    .ngx-slider {
      @extend .custom-slider-type2;
    }

    ngx-charts-area-chart-stacked,
    ngx-charts-bar-vertical-stacked {
      margin-right: 30px;
      .legend-labels {
        margin-left: 10px !important;
        min-width: 180px;
      }
    }
  }
}
