@use 'sass:map';
// @use '@angular/material' as mat;
@use 'dependencies/compatibility.style.scss' as mat;
@import '../../node_modules/@oculus/components/theming';
@import '../../node_modules/@oculus/styling/ellipse';
@import 'dependencies/variables.style.scss';
@import 'components/custom/custom-mat-button-toggle-group.theme.scss';
@import 'components/custom/custom-mat-button.theme.scss';
@import 'components/custom/custom-mat-card.theme.scss';
@import 'components/custom/custom-mat-dialog.theme.scss';
@import 'components/custom/custom-mat-error.theme.scss';
@import 'components/custom/custom-mat-expansion-panel.theme.scss';
@import 'components/custom/custom-mat-form-field.theme.scss';
@import 'components/custom/custom-mat-icon.theme.scss';
@import 'components/custom/custom-mat-list.theme.scss';
@import 'components/custom/custom-mat-menu.theme.scss';
@import 'components/custom/custom-mat-select.theme.scss';
@import 'components/custom/custom-mat-sidenav.theme.scss';
@import 'components/custom/custom-mat-slide-toggle.theme.scss';
@import 'components/custom/custom-mat-snackbar.theme.scss';
@import 'components/custom/custom-mat-stepper.theme.scss';
@import 'components/custom/custom-mat-table.theme.scss';
@import 'components/custom/custom-mat-toolbar.theme.scss';
@import 'components/custom/custom-ngx-slider.theme.scss';
@import 'components/custom/custom-ngx-skeleton-loader.scss';
@import 'components/custom/custom-mat-checkbox.theme.scss';
@import 'components/custom/custom-mat-progress.theme.scss';
@import 'components/custom/custom-mat-chip-grid.theme.scss';
@import 'components/custom/custom-mat-tab.theme.scss';
@import 'components/eyes/eyes-stepper-nav.theme.scss';
@import 'components/eyes/eyes-tooltip.theme.scss';
@import 'components/general/general-scrollbar.theme.scss';
@import 'components/prosumer/prosumer-global.theme.scss';
@import 'components/prosumer/prosumer-about-page.theme.scss';
@import 'components/prosumer/prosumer-cash-flow.theme.scss';
@import 'components/prosumer/prosumer-chart-tooltip.theme.scss';
@import 'components/prosumer/prosumer-co2emission.theme.scss';
@import 'components/prosumer/prosumer-equipment.theme.scss';
@import 'components/prosumer/prosumer-compare-dialog.theme.scss';
@import 'components/prosumer/prosumer-dashboard-page.theme.scss';
@import 'components/prosumer/prosumer-dispatch.theme.scss';
@import 'components/prosumer/prosumer-efficiency-matrix.theme.scss';
@import 'components/prosumer/prosumer-energy-balance.theme.scss';
@import 'components/prosumer/prosumer-energy-vector-list.theme.scss';
@import 'components/prosumer/prosumer-energy-vector-select.theme.scss';
@import 'components/prosumer/prosumer-energy-vectors.theme.scss';
@import 'components/prosumer/prosumer-ev-toggle-group.theme.scss';
@import 'components/prosumer/prosumer-file-upload.theme.scss';
@import 'components/prosumer/prosumer-home-page.theme.scss';
@import 'components/prosumer/prosumer-main-output.theme.scss';
@import 'components/prosumer/prosumer-project-list-page.theme.scss';
@import 'components/prosumer/prosumer-scenario-compare-page.theme.scss';
@import 'components/prosumer/prosumer-scenario-name.theme.scss';
@import 'components/prosumer/prosumer-scenario-page.theme.scss';
@import 'components/prosumer/prosumer-scenario-upload.theme.scss';
@import 'components/prosumer/prosumer-series-tooltip.theme.scss';
@import 'components/prosumer/prosumer-shared-usage-chart.theme.scss';
@import 'components/prosumer/prosumer-share-dialog.theme.scss';
@import 'components/prosumer/prosumer-simulation-results.theme.scss';
@import 'components/prosumer/prosumer-stacked-area-chart.theme.scss';
@import 'components/prosumer/prosumer-summary-form.theme.scss';
@import 'components/prosumer/prosumer-system-visualization.theme.scss';
@import 'components/prosumer/prosumer-time-form.theme.scss';
@import 'components/prosumer/prosumer-usages.theme.scss';
@import 'components/prosumer/prosumer-yearly-chart-input.theme.scss';
@import 'components/prosumer/prosumer-yearly-loads-interval.theme.scss';
@import 'components/prosumer/prosumer-solar-tab.theme.scss';
@import 'components/prosumer/prosumer-wind-tab.theme.scss';
@import 'components/prosumer/prosumer-coordinates-map.theme.scss';
@import 'components/prosumer/prosumer-tooltip.theme.scss';
@import 'components/prosumer/prosumer-chart-theme.scss';
@import 'components/prosumer/prosumer-flows.theme.scss';

@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-themes($prosumer-theme);

/* Oculus */
@include oculus-drawer-theme($prosumer-theme);
@include oculus-header-theme($prosumer-theme);

/* Custom */
@include custom-mat-button-toggle-group-theme($prosumer-theme);
@include custom-mat-button-theme($prosumer-theme);
@include custom-mat-card-theme($prosumer-theme);
@include custom-mat-dialog-theme($prosumer-theme);
@include custom-mat-error-theme($prosumer-theme);
@include custom-mat-expansion-panel-theme($prosumer-theme);
@include custom-mat-form-field-theme($prosumer-theme);
@include custom-mat-icon-theme($prosumer-theme);
@include custom-mat-list-theme($prosumer-theme);
@include custom-mat-menu-theme($prosumer-theme);
@include custom-mat-select-theme($prosumer-theme);
@include custom-mat-sidenav-theme($prosumer-theme);
@include custom-mat-slide-toggle-theme($prosumer-theme);
@include custom-mat-snackbar-theme($prosumer-theme);
@include custom-mat-stepper-theme($prosumer-theme);
@include custom-mat-table-theme($prosumer-theme);
@include custom-mat-toolbar-theme($prosumer-theme);
@include custom-ngx-slider-theme($prosumer-theme);
@include custom-ngx-skeleton-loader-theme($prosumer-theme);
@include custom-mat-checkbox-theme($prosumer-theme);
@include custom-mat-progress-theme($prosumer-theme);
@include custom-mat-chip-grid-theme($prosumer-theme);
@include custom-mat-tab-theme($prosumer-theme);

/* Eyes */
@include eyes-stepper-nav-theme($prosumer-theme);
@include eyes-tooltip-theme($prosumer-theme);

/* General */
@include general-scrollbar-theme($prosumer-theme);

/* Prosumer */
@include prosumer-global-theme($prosumer-theme);
@include prosumer-about-page-theme($prosumer-theme);
@include prosumer-cash-flow-theme($prosumer-theme);
@include prosumer-chart-tooltip-theme($prosumer-theme);
@include prosumer-co2emission-theme($prosumer-theme);
@include prosumer-equipment-theme($prosumer-theme);
@include prosumer-compare-dialog-theme($prosumer-theme);
@include prosumer-dashboard-page-theme($prosumer-theme);
@include prosumer-dispatch-theme($prosumer-theme);
@include prosumer-efficiency-matrix-theme($prosumer-theme);
@include prosumer-energy-balance-theme($prosumer-theme);
@include prosumer-energy-vector-list-theme($prosumer-theme);
@include prosumer-energy-vector-select-theme($prosumer-theme);
@include prosumer-energy-vectors-theme($prosumer-theme);
@include prosumer-ev-toggle-group-theme($prosumer-theme);
@include prosumer-file-upload-theme($prosumer-theme);
@include prosumer-home-page-theme($prosumer-theme);
@include prosumer-main-output-theme($prosumer-theme);
@include prosumer-project-list-page-theme($prosumer-theme);
@include prosumer-scenario-compare-page-theme($prosumer-theme);
@include prosumer-scenario-name-theme($prosumer-theme);
@include prosumer-scenario-page-theme($prosumer-theme);
@include prosumer-scenario-upload-theme($prosumer-theme);
@include prosumer-series-tooltip-theme($prosumer-theme);
@include prosumer-shared-usage-chart-theme($prosumer-theme);
@include prosumer-share-dialog-theme($prosumer-theme);
@include prosumer-simulation-results-theme($prosumer-theme);
@include prosumer-stacked-area-chart-theme($prosumer-theme);
@include prosumer-summary-form-theme($prosumer-theme);
@include prosumer-system-visualization-theme($prosumer-theme);
@include prosumer-time-form-theme($prosumer-theme);
@include prosumer-usages-theme($prosumer-theme);
@include prosumer-yearly-chart-input-theme($prosumer-theme);
@include prosumer-yearly-loads-interval-theme($prosumer-theme);
@include prosumer-solar-tab-theme($prosumer-theme);
@include prosumer-wind-tab-theme($prosumer-theme);
@include prosumer-coordinates-map-theme($prosumer-theme);
@include prosumer-tooltip-theme($prosumer-theme);
@include prosumer-chart-theme($prosumer-theme);
@include prosumer-flows-theme($prosumer-theme);
