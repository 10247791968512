@use './colors.scss' as color;

$default: 'Lato, monospace';

@mixin gradient-font() {
  background-color: color.$logo;
  background-image: color.$gradient !important;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
