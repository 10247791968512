@mixin custom-mat-stepper-theme($theme) {
  .mat-horizontal-content-container {
    padding: 5px !important;
  }

  .mat-stepper-horizontal {
    background-color: $background-color;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 20px;
  }

  .mat-horizontal-stepper-header,
  .mat-horizontal-stepper-header:hover {
    transition: $transition-bg;
  }

  // To fix the hiding of tooltip anchor in scenario steps
  .mat-step-label {
    overflow: visible !important;
  }

  /* Start - PR-820 Space Optimization */
  .mat-horizontal-stepper-header-container {
    margin-bottom: 0;

    .mat-horizontal-stepper-header {
      height: 40px;
    }
  }
  /* End - PR-820 Space Optimization */
}
