@mixin custom-mat-select-theme($theme) {
  .mat-mdc-select,
  .mat-mdc-option.mdc-list-item {
    div.mat-mdc-select-value,
    span {
      height: 20px;
      align-content: center;
    }
  }
}
