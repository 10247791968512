@mixin custom-mat-button-theme($theme) {
  .mat-mdc-mini-fab {
    transition: $transition-opacity !important;
    &:hover {
      opacity: $opacity-default;
    }
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    border-radius: 20px !important;
    transition: $transition-opacity !important;
    &:hover {
      opacity: $opacity-default;
    }
  }

  .mat-mdc-icon-button {
    &.prosumer-success {
      color: $green;
    }
  }

  button.mat-mdc-button,
  button.mat-mdc-raised-button,
  button.mat-mdc-unelevated-button {
    text-transform: uppercase;
    min-width: 88px;
  }

  .mat-mdc-unelevated-button {
    border-radius: 5px !important;
    border: 2px solid transparent !important;
  }

  .mat-mdc-unelevated-button.mat-mdc-button-base:not(.mat-primary):not(
      .mat-accent
    ):not(.mat-warn):not([disabled]) {
    border: 2px solid $blue !important;
    color: $blue !important;
  }

  button {
    &:not(.mat-mdc-mini-fab):not(.mat-mdc-fab):not(.mat-mdc-icon-button) {
      border-radius: 5px !important;
    }
  }
}
