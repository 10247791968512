@mixin custom-ngx-skeleton-loader-theme($theme) {
  .mat-mdc-table ngx-skeleton-loader,
  form ngx-skeleton-loader {
    height: 30px !important;

    .loader {
      height: 10px !important;
      margin-top: 10px !important;
    }
  }
}
