$opacity: 0.8;

.cesium-viewer-bottom {
  display: none !important;
}

.gis-stats-container div {
  right: 16px !important;
  left: auto !important;
  top: 72px !important;
}

.gis-tooltip,
.gis-details-tooltip,
.gis-autocomplete {
  background-color: rgba(#000, $opacity) !important;
}

.gis-autocomplete {
  margin-top: 10px;
  padding: 0;
  .mat-mdc-option {
    & span {
      color: #fff;
      font-size: 14px;
      display: inline-block;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: white !important;
      }
    }

    &:hover {
      background-color: #8c8c8c29 !important;
      & span {
        color: white !important;
      }
    }
  }
  .mat-mdc-option.mat-mdc-option-active {
    color: #fff !important;
    background-color: rgba(#fff, 0.1) !important;
  }
}

.gis-more-panel {
  padding: 5px 8px;
  min-width: 0 !important;
  min-height: 0 !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.gis-pointer-events-none {
  pointer-events: none !important;
}

.gis-details-tooltip {
  padding: 10px 18px;
  color: #fff;
  border-radius: 4px;
  transition: opacity 200ms ease-in-out;
}

.gis-remove-max-height {
  max-height: none !important;
}

.gis-filter-panel,
.gis-map-view-panel,
.gis-style-select-panel {
  margin-top: 10px;
}

.gis-style-select-panel {
  .mat-badge-content {
    right: 12px !important;
    top: 12px !important;
    width: 15px !important;
    height: 15px !important;
    line-height: 15px !important;
    left: unset !important;
    bottom: unset !important;
  }
  .mat-button-toggle-label-content {
    padding: 5px 8px !important;
  }
}

.gis-mini-toolbox,
.filter-button,
.delete-button,
.unlock-button,
.map-view-control .mat-mdc-icon-button,
.gis-map-style-select .mat-icon-button,
.gis-map-import-data .mat-icon-button,
.gis-map-export-data .mat-icon-button,
.gis-map-search-bar,
.gis-map-mode,
.gis-map-view,
.gis-map-history .mat-icon-button {
  opacity: $opacity;
  transition: opacity 200ms ease-in-out;
}

.gis-mini-toolbox:hover,
.filter-button:hover,
.delete-button:hover,
.unlock-button:hover,
.unlock-button-active,
.map-view-control .mat-mdc-icon-button:not([disabled='true']):hover,
.gis-map-style-select .mat-icon-button:not([disabled='true']):hover,
.gis-map-import-data .mat-icon-button:not([disabled='true']):hover,
.gis-map-export-data .mat-icon-button:not([disabled='true']):hover,
.gis-map-mode:hover,
.gis-map-view:hover,
.gis-map-history .mat-icon-button:not([disabled='true']):hover {
  opacity: 1;
}

.cesium-viewer {
  font-family: inherit;
}

.cesium-performanceDisplay-defaultContainer {
  top: auto;
  bottom: 50px;
  right: 15px;
  min-width: 70px;

  .cesium-performanceDisplay {
    background-color: rgba(#000, $opacity);
    padding: 8px;
    border-radius: 4px;
    font: inherit;
    font-size: 12px;
    border: none;

    .cesium-performanceDisplay-ms,
    .cesium-performanceDisplay-fps,
    .cesium-performanceDisplay-throttled {
      color: #fff;
    }
  }
}

gis-details-overlay,
gis-map-search-bar {
  .mat-mdc-form-field,
  .mat-mdc-form-field .mat-mdc-floating-label {
    color: #fff;
  }
  .mat-mdc-form-field .mat-form-field-underline {
    background-color: #fff;
  }

  $disabled-opacity-offset: 0.5;

  .mat-mdc-form-field.mat-form-field-disabled,
  .mat-form-field.mat-form-field-disabled .mat-input-element,
  .mat-form-field.mat-form-field-disabled .mat-form-field-label {
    color: rgba(#fff, $opacity - $disabled-opacity-offset);
  }
  .mat-mdc-form-field.mat-form-field-disabled .mat-form-field-underline {
    background-color: rgba(
      #fff,
      $opacity - $disabled-opacity-offset
    ) !important;
  }

  button[disabled='true'] {
    color: rgba(#f18f09, $opacity - $disabled-opacity-offset) !important;
  }
}

.gis-details-overlay-panel {
  .details-container {
    background: rgba(#000, 0.9) !important;
    color: #fff !important;
  }
}

.cesium-selection-wrapper {
  padding: 20px;
  background-color: rgba($color: #000, $alpha: 0.5);
}
