@mixin prosumer-shared-usage-chart-theme($theme) {
  prosumer-shared-usage-chart {
    .prosumer-tooltip {
      padding: 10px 5px 20px;

      .prosumer-tooltip-item-color {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        color: $smoke-gray;
        border-radius: 3px;
      }

      .prosumer-tooltip-item-series {
        padding-right: 8px;
      }

      .prosumer-tooltip-item-value {
        text-align: right;
      }

      .prosumer-tooltip-item.current {
        font-weight: bolder;
      }
    }

    ngx-charts-legend {
      margin-left: 22.5%;
    }
  }
}
