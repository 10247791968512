@mixin prosumer-scenario-name-theme($theme) {
  prosumer-scenario-name {
    .mat-mdc-form-field {
      &.ng-invalid {
        .mdc-notched-outline > * {
          border-color: var(
            --mdc-outlined-text-field-error-outline-color
          ) !important;
        }
        .mdc-notched-outline .mat-mdc-floating-label > * {
          color: var(--mdc-outlined-text-field-error-outline-color) !important;
        }
      }
    }
  }
}
