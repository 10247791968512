@use '@angular/material' as mat;
@mixin prosumer-system-visualization-theme($theme) {
  prosumer-system-visualization {
    .mat-button-toggle-group {
      @extend .custom-button-toggle-group;
    }

    .optimize-button-container {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .optimize-button-container .mat-mdc-fab,
    .optimize-button-container .mat-mdc-mini-fab {
      $icon-dimension: 35px;
      background-color: $gray !important;

      &.optimized {
        transition: $transition-all;
        background-color: $green !important;
      }

      .mat-icon {
        width: $icon-dimension;
        height: $icon-dimension;
        line-height: 26px;
        font-size: $icon-dimension;
        place-content: center;
        color: white;
      }
    }
  }

  prosumer-node-view-container {
    .mdc-notched-outline {
      @include mat.elevation(1);
      background: $background-color;
      border-radius: 5px;
    }

    .actions-container {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 100;

      .actions-button {
        @include mat.elevation(1);
        background: $background-color;
      }
    }
  }

  prosumer-overview-diagram,
  prosumer-node-view-diagram {
    border: 2px dotted $disabled-color;
    border-radius: 8px;

    &.optimized {
      transition: $transition-all;
      border-color: $green;
      border-style: solid;
    }
  }

  prosumer-diagram-fluids-legend {
    $icon-dimension: 35px;

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
      box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
      border: 2px solid $white;

      & .mdc-button__label,
      mat-icon {
        color: white !important;
        padding: 0 !important;
        line-height: $icon-dimension;
      }

      .mat-icon {
        stroke: $white;
        width: $icon-dimension;
        height: $icon-dimension;
        line-height: $icon-dimension;
        svg {
          height: $icon-dimension;
        }
      }
    }
  }
}
