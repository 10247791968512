@use './form-field.scss' as field;

mat-form-field {
  div.mat-mdc-form-field-wrapper {
    div.mat-mdc-form-field-flex {
      @include field.border();

      div.mat-mdc-form-field-outline {
        .mat-mdc-form-field-outline-start,
        .mat-mdc-form-field-outline-end {
          @include field.border();
        }
      }
    }
  }
}

button {
  &:not(.mat-mdc-mini-fab):not(.mat-mdc-fab):not(.mat-mdc-icon-button) {
    @include field.border();
  }
}
