@mixin prosumer-yearly-loads-interval-theme($theme) {
  prosumer-yearly-loads-interval {
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-body-content {
      overflow: hidden !important;
    }

    .mat-mdc-tab-header {
      padding-left: 310px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-disabled {
      .eyes-warn {
        opacity: 0.6 !important;
      }
    }
  }

  prosumer-enhanced-loads-input {
    .mat-icon.prosumer-enhanced-load-anchor {
      $dim: 20px;

      font-size: $dim;
      height: $dim;
      width: $dim;

      cursor: pointer;
      display: inline !important;
      margin-right: 3px;
    }
    .mat-mdc-form-field.prosumer-enhanced-load-input {
      width: 145px;
      font-size: x-small;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .error-message-panel {
      width: 210px;
      max-height: 120px;
      overflow-y: auto;
      background-color: #fff4f4;
      padding: 10px;
      margin-top: 10px;
    }

    .dataset-upload {
      &:hover {
        border-color: #007acd;
      }
      width: 210px;
      height: 300px;
      border: 3px dashed gray;
      border-radius: 10%;
    }

    .data-line {
      padding: 0;
      margin-bottom: 1px;
      align-items: center;
      background-color: #f5f5f5;
      display: flex;
      width: 210px;
    }
    .data-line:nth-child(odd) {
      background: #ffffff;
    }

    .data-line:nth-child(odd) > .timestamp {
      background-color: #4fc3f7;
    }

    .data-index {
      margin-left: 4px;
      min-width: 32px;
      font-size: 10px;
      display: flex;
    }

    .timestamp {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 1.5rem;
      padding: 0.5rem 0.3rem;
      background-color: #007acd;
      color: #ffffff;
      font-size: 0.6rem;
      line-height: 0.6rem;
    }

    .hours {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .data-value {
      display: flex;
      padding-left: 0.3rem;
      width: 140px;
      font-size: 12px;
    }

    .data-value.input {
      //height: 25px !important;
      //padding-left: 10px;
    }

    a.prosumer-manual-input {
      cursor: pointer !important;
      color: #007acd !important;
    }

    .title-paste {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: smaller;
      border: none;
      border: 1px solid $blue;
      outline: none !important;
      justify-content: center;
      //padding-left: .3rem;
      height: 1.3rem;
      display: flex;
      align-items: center;
      width: 140px;
    }

    .single-value-input button {
      font-size: smaller;
      height: 1.55rem;
      width: 25px;
      border: none;
      background-color: #007acd;
      margin-top: 30px;
      margin-bottom: 20px;
      justify-content: center;
      padding: 2px 4px 1px 1px;
    }

    .drag-over {
      border: 3px dashed #007acd;
      border-radius: 10%;
    }

    .drop-target {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .single-value-input {
      display: flex;
      justify-content: center;
      flex-direction: row;
      height: 100%;
      padding: 1px 3px;
    }

    .csv-icon {
      margin-top: 0.5rem;
      opacity: 0.7;
    }
  }
}
