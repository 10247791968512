@mixin custom-ngx-slider-theme($theme) {
  .custom-slider-type1 {
    .ngx-slider-bar,
    .ngx-slider-tick {
      background: $hover-color !important;
    }

    .ngx-slider-tick.ngx-slider-selected {
      background-color: $orange !important;
    }

    .ngx-slider-limit {
      color: $blue !important;
    }

    .ngx-slider-span {
      color: $text-color !important;
      margin-bottom: 12px;
    }

    .ngx-slider-selection {
      background-color: $blue !important;
    }

    .ngx-slider-pointer {
      background-color: $blue !important;
      &:focus {
        outline: none;
      }

      &.ngx-slider-active {
        &::after {
          opacity: 0.8;
          width: 16px !important;
          height: 16px !important;
          top: 8px !important;
          left: 8px !important;
          border-radius: 8px !important;
          background-color: $light-gray !important;
        }
      }
    }

    .ngx-slider-tick {
      display: none;
    }

    &[disabled],
    &.disabled-slider {
      pointer-events: none;
      .ngx-slider-pointer {
        background-color: $disabled-color !important;
      }
      .ngx-slider-selection {
        background-color: $disabled-color !important;
      }
      .ngx-slider-bar:not(.ngx-slider-selection) {
        background-color: $light-gray !important;
      }
    }
  }

  .custom-slider-type2 {
    .ngx-slider-bar {
      height: 2px;
    }

    .ngx-slider-pointer {
      width: 8px;
      height: 16px;
      top: auto; /* to remove the default positioning */
      bottom: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .ngx-slider-pointer:after {
      display: none;
    }

    .ngx-slider-bubble {
      bottom: 14px;
      font-size: 12px;
    }

    .ngx-slider-tick-legend {
      top: 14px;
      font-size: 12px;
      margin: 0; // stacked-area-chart
    }

    .ngx-slider-limit {
      font-weight: bold;
    }

    .ngx-slider-tick {
      width: 1px;
      height: 10px;
      margin-left: 4px;
      border-radius: 0;
      top: -1px;
    }

    .ngx-slider-bar,
    .ngx-slider-tick {
      background: $hover-color !important;
    }

    .ngx-slider-selection {
      background: $orange !important;
    }

    .ngx-slider-pointer {
      background-color: $blue !important;
    }

    .ngx-slider-limit {
      color: $blue !important;
    }

    .ngx-slider-tick.ngx-slider-selected {
      background: $orange !important;
    }
  }
}
