@mixin prosumer-summary-form-theme($theme) {
  prosumer-summary-form {
    .mat-icon.warning {
      color: $yellow;
    }

    .mat-icon.success {
      color: $green;
    }

    .mat-icon.failure {
      color: $red;
    }
  }
}
