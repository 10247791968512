@mixin prosumer-dispatch-theme($theme) {
  prosumer-dispatch {
    .mat-button-toggle-group {
      @extend .custom-button-toggle-group;

      .mat-button-toggle.show-subscript-margin {
        margin-bottom: var(
          --mat-form-field-hide-show-subscript-margin
        ) !important;
      }
    }

    .ngx-slider {
      @extend .custom-slider-type2;
    }

    ngx-charts-area-chart-stacked,
    ngx-charts-bar-vertical-stacked {
      margin-right: 30px;
      .legend-labels {
        margin-left: 10px !important;
        min-width: 180px;
      }
    }
  }
}
