@mixin prosumer-cash-flow-theme($theme) {
  prosumer-cash-flow {
    .mat-mdc-tab-body-content {
      height: 100%;
      overflow: hidden;
    }

    .chart-legend {
      .legend-labels {
        margin-left: 10px !important;
        width: 75% !important;
      }
    }
  }
}
