@mixin custom-mat-form-field-theme($theme) {
  // Special form field to only show error message or hints; very useful to non-material inputs such as tables, etc.
  .mat-mdc-form-field.hidden {
    .mat-mdc-text-field-wrapper {
      height: 0;
      .mat-mdc-form-field-flex,
      .mat-mdc-form-field-infix,
      .mdc-line-ripple {
        height: 0;
        visibility: hidden;
      }
    }
  }

  /* Start - PR-820 Space Optimization */
  .mat-mdc-form-field.mat-form-field-appearance-outline {
    // height: -webkit-fill-available;
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0 !important;
      border-radius: 5px !important;
    }
    .mat-mdc-form-field-infix {
      // padding: 12px 0.5em 12px !important;
      min-height: var(--mat-form-field-container-height);
      place-content: center !important;
      padding: 8px 0 !important;
      &:has(textarea) {
        min-height: var(--mat-form-field-container-height);
        textarea {
          min-height: var(--mat-form-field-container-height);
        }
      }
    }
    // .mat-mdc-form-field-subscript-wrapper:has(div:empty) {
    //   display: none;
    // }
  }

  // adjustments: subscript
  .show-subscript-margin {
    margin-bottom: var(--mat-form-field-hide-show-subscript-margin);
  }
  .hide-show-subscript {
    &:has(.hide-empty-subscript):has(
        .mat-mdc-form-field-subscript-wrapper div:empty
      ) {
      margin-bottom: var(--mat-form-field-hide-show-subscript-margin);
    }
  }
  .mat-mdc-form-field {
    padding-top: 5px;

    &.hide-subscript,
    &.hide-empty-subscript:has(
        .mat-mdc-form-field-subscript-wrapper div:empty
      ) {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
    & + mat-progress-bar {
      margin-bottom: -8px;
      padding-top: 4px;
    }
  }

  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-subscript-wrapper {
    padding-top: 1.34375em !important;
  }
  /* End - PR-820 Space Optimization */

  .mat-mdc-form-field.mat-form-field-appearance-outline {
    .mat-mdc-form-field-error-wrapper,
    .mat-mdc-form-field-hint-wrapper {
      padding: 0 6px;

      .mat-mdc-form-field-error {
        margin-top: -4px;
      }
    }
  }

  .mat-mdc-form-field {
    div.mat-mdc-text-field-wrapper {
      div.mat-mdc-form-field-flex {
        border-radius: 5px !important;
        div.mdc-notched-outline {
          .mdc-notched-outline__leading {
            border-radius: 5px 0px 0px 5px !important;
          }
          /* [START] Workaround: mat-label & mat-suffix issue */
          .mdc-notched-outline__notch {
            --mat-mdc-icon-suffix-count: 0;
            width: calc(
              100% - 50px - calc(20px * var(--mat-mdc-icon-suffix-count))
            );
          }
          /* [END] Workaround: mat-label & mat-suffix issue */
          .mdc-notched-outline__trailing {
            border-radius: 0px 5px 5px 0px !important;
          }
        }
      }
    }
    .mat-datepicker-toggle .mat-mdc-icon-button {
      height: var(--mat-form-field-container-height);
      width: var(--mat-form-field-container-height);
      padding: 9px;
      & > span {
        height: calc(var(--mat-form-field-container-height) - 4px);
        width: calc(var(--mat-form-field-container-height) - 4px);
        margin: 2px;
      }
    }
  }
  * {
    --mat-form-field-container-height: 40px;
    --mat-form-field-hide-show-subscript-margin: 21.5px;
    //   --mat-form-field-outlined-label-text-populated-size: 14px;
    //   --mdc-outlined-text-field-label-text-size: 14px;
    //   --mat-form-field-container-text-size: 14px;
    //   --mat-select-trigger-text-size: 14px;
  }
}
