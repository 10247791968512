@mixin prosumer-share-dialog-theme($theme) {
  prosumer-share-dialog {
    .mat-mdc-form-field .mat-mdc-form-field-infix {
      padding: 2px 0.5em 9px !important;
    }

    .mat-mdc-chip {
      border-radius: 5px;
    }

    input {
      min-height: 32px;
    }

    .mat-mdc-focus-indicator {
      background: none;
    }

    .mat-mdc-button-ripple {
      display: none;
    }

    .mat-mdc-chip-grid {
      width: 80%;
    }

    .mat-mdc-chip {
      word-break: break-all;
    }

    .mat-mdc-chip.duplicate {
      background-color: #ff8282;
      color: #fff;
    }

    .mat-mdc-chip.duplicate .mat-mdc-chip-remove {
      color: #fff;
    }

    .share .action-menu {
      padding: 0;
      font-size: 12px;
      width: max-content;
    }

    .mat-mdc-form-field {
      margin-top: 21px;
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline
      .mat-mdc-form-field-infix {
      padding: 10px 0.5em 10px !important;
    }

    .prosumer-shared-with-detail {
      border: 1px dotted $disabled-color;
      .share-with-user-fullname {
        font-size: 14px;
      }
      .prosumer-image-placeholder {
        background-color: $disabled-color;
      }

      .action-menu {
        font-size: 12px;
        width: max-content;

        mat-icon {
          font-size: 18px;
          height: 18px;
          width: 18px;
          margin-right: 5px;
        }

        &:hover {
          .mat-mdc-focus-indicator {
            opacity: 0;
          }
        }
      }
    }

    .prosumer-changes-warning {
      border: 1px solid $yellow;
      border-radius: 5px;
      padding: 10px;
      background: rgba($yellow, 0.1);
    }
  }
}
